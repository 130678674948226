<div
  *ngIf="isOpen$ | async"
  class="modal-overlay min-h-screen fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
>
  <div
    class="modal-content bg-white rounded"
    [style.width]="width"
    (click)="$event.stopPropagation()"
  >
    <ng-content></ng-content>
  </div>
</div>
