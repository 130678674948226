import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class ClerkService {
  private clerkInstance: any;

  constructor(
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  async initializeClerk(): Promise<void> {
    if (isPlatformBrowser(this.platformId)) {
      //console.log('Starting Clerk initialization');
      const { Clerk } = await import('@clerk/clerk-js');
      this.clerkInstance = new Clerk(environment.clerk_key);
      await this.clerkInstance.load();
      //console.log('Clerk initialized');
    }
  }

  getClerkInstance(): any | null {
    if (isPlatformBrowser(this.platformId)) {
      //console.log('Getting Clerk instance');
      return this.clerkInstance;
    }
    return null;
  }

  isAuthenticated(): boolean {
    if (isPlatformBrowser(this.platformId) && this.clerkInstance) {
      const isAuth = !!this.clerkInstance.user;
      //console.log('User is authenticated:', isAuth);
      return isAuth;
    }
    return false;
  }

  getUser(): any {
    if (isPlatformBrowser(this.platformId) && this.clerkInstance) {
      return this.clerkInstance.user;
    }
  }

  signOut(): void {
    this.clerkInstance.signOut();
  }

  isInitialized(): boolean {
    if (isPlatformBrowser(this.platformId) && this.clerkInstance) {
      //console.log('Clerk is loaded:', this.clerkInstance.loaded);
      return this.clerkInstance.loaded;
    }
    return false;
  }

  async getToken(): Promise<string | null> {
    const session = this.clerkInstance.session;
    if (session) {
      return await session.getToken();
    }
    return null;
  }

  clearTelemetryState(): void {
    localStorage.removeItem('clerk_telemetry_throttler');
  }

  handleAuthentication(): void {
    if (this.isAuthenticated()) {
      this.router.navigate(['/dashboard']);
    }
  }
}
