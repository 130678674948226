import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard/dashboard.component';
import { BalancePageComponent } from './dashboard/balance/pages/balance-page/balance-page.component';
import { PaymentSuccessfullComponent } from './dashboard/balance/pages/payment-successfull/payment-successfull.component';
import { PaymentCanceledComponent } from './dashboard/balance/pages/payment-canceled/payment-canceled.component';
import { ReportsPageComponent } from './dashboard/reports/pages/reports-page/reports-page.component';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./web/home/module/home/home.module').then((m) => m.HomeModule),
  },
  {
    path: 'auth',
    loadChildren: () =>
      import('./web/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'guides/:guideName',
    loadChildren: () =>
      import('./web/guides/module/guides/guides.module').then(
        (m) => m.GuidesModule
      ),
  },
  {
    component: DashboardComponent,
    path: 'dashboard',
    children: [
      { path: '', redirectTo: 'document', pathMatch: 'full' },
      {
        path: 'chat',
        loadChildren: () =>
          import(
            './dashboard/chat/modules/chat-module/chat-module.module'
          ).then((m) => m.ChatModuleModule),
      },
      {
        path: 'document',
        loadChildren: () =>
          import('./dashboard/documents/modules/document/document.module').then(
            (m) => m.DocumentModule
          ),
      },
      {
        path: 'text',
        loadChildren: () =>
          import('./dashboard/texts/modules/text/text.module').then(
            (m) => m.TextModule
          ),
      },
      {
        path: 'q-and-a',
        loadChildren: () =>
          import('./dashboard/q-and-a/modules/q-and-a/q-and-a.module').then(
            (m) => m.QAndAModule
          ),
      },
      {
        path: 'connect',
        loadChildren: () =>
          import('./dashboard/connect/modules/connect/connect.module').then(
            (m) => m.ConnectModule
          ),
      },
      /*       {
        path: 'settings',
        component: SettingsPageComponent,
      }, */
      {
        path: 'reports',
        loadChildren: () =>
          import('./dashboard/reports/modules/report/report.module').then(
            (m) => m.ReportModule
          ),
      },
      {
        path: 'balance',
        loadChildren: () =>
          import('./dashboard/balance/modules/balance/balance.module').then(
            (m) => m.BalanceModule
          ),
      },
    ],
  },
  {
    path: 'home',
    loadChildren: () =>
      import('./web/home/module/home/home.module').then((m) => m.HomeModule),
  },
  {
    path: 'terms-of-use',
    loadChildren: () =>
      import(
        './web/terms-of-use/modules/terms-of-use/terms-of-use.module'
      ).then((m) => m.TermsOfUseModule),
  },
  {
    path: 'privacy',
    loadChildren: () =>
      import('./web/privacy/modules/privacy/privacy.module').then(
        (m) => m.PrivacyModule
      ),
  },
  {
    path: 'help-center',
    loadChildren: () =>
      import('./web/help-center/modules/help-center/help-center.module').then(
        (m) => m.HelpCenterModule
      ),
  },

  //{ path: '**', component: PageNotFoundComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledBlocking',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
