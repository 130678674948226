import {
  Component,
  HostListener,
  Inject,
  Input,
  OnInit,
  PLATFORM_ID,
} from '@angular/core';
import { ModalService } from '../../services/modal/modal.service';
import { isPlatformBrowser } from '@angular/common';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent implements OnInit {
  @Input() id!: string; // Modal ID is required
  @Input() width: string = '500px';
  @Input() height: string = '300px';
  isOpen$!: Observable<boolean>;

  constructor(
    public modalService: ModalService,
    @Inject(PLATFORM_ID)
    private platformId: any
  ) {}

  ngOnInit(): void {
    if (!this.id) {
      throw new Error('Modal must have an ID.');
    }
    this.modalService.register(this.id);
    this.isOpen$ = this.modalService.isOpen$(this.id);

    if (isPlatformBrowser(this.platformId)) {
      document.addEventListener('click', this.onDocumentClick.bind(this));
    }
  }

  closeModal() {
    this.modalService.close(this.id);
  }

  onDocumentClick(event: MouseEvent): void {
    const target = event.target as HTMLElement;
    if (target.classList.contains('modal-overlay')) {
      this.closeModal();
    }
  }
}
