import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ReportService {
  private API_URL = environment.backend_api_url;
  constructor(private _http: HttpClient) {}

  getReports(user_id: number, page: number, limit: number): Observable<any> {
    return this._http.get<any>(
      `${this.API_URL}/reports/user/${user_id}?page=${page}&limit=${limit}`,

      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        }),
      }
    );
  }

  getAllReports(user_id: number): Observable<any> {
    return this._http.get<any>(
      `${this.API_URL}/reports/user/all/${user_id}`,

      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        }),
      }
    );
  }

  deleteFlaggedQuestion(flagged_question_id: number): Observable<any> {
    return this._http.delete<any>(
      `${this.API_URL}/reports/${flagged_question_id}`,

      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        }),
      }
    );
  }
}
