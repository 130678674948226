export class AbsError extends Error {
  override message: string;
  casue: any;

  constructor(message: string, cause?: any) {
    super();
    this.message = message;
    this.casue = cause;
  }
}
