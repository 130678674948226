import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DashboardComponent } from './dashboard/dashboard/dashboard.component';
import { AlertNotificationsComponent } from './core/components/alert-notifications/alert-notifications.component';
import { ModalComponent } from './core/components/modal/modal.component';

import { WebSharedModule } from './web/web-shared/web-shared.module';
import { ClerkService } from './core/services/clerk/clerk.service';
import { AuthHeaderInterceptor } from './core/interceptors/auth-header.interceptor';

export function initializeClerkService(
  clerkService: ClerkService
): () => Promise<void> {
  return () => clerkService.initializeClerk();
}

@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    AlertNotificationsComponent,
    ModalComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    WebSharedModule,
  ],
  providers: [
    ClerkService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeClerkService,
      deps: [ClerkService],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS, // Register the AuthHeaderInterceptor
      useClass: AuthHeaderInterceptor,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
