import { Component, OnDestroy, OnInit } from '@angular/core';
import { BasePage } from 'src/app/core/extenders/BasePage';
import { UserView } from 'src/app/core/models/UserView';
import { AlertNotificationsService } from 'src/app/core/services/alerts/alert-notifications.service';
import { RedirectService } from 'src/app/core/services/redirect/redirect.service';
import { UserService } from 'src/app/core/services/user/user.service';
import { ClerkService } from 'src/app/core/services/clerk/clerk.service';
import { filter, firstValueFrom, Subscription } from 'rxjs';
import { ModalCommunicationService } from 'src/app/core/services/modal-communication/modal-communication.service';
import { ModalService } from 'src/app/core/services/modal/modal.service';
import { ReportService } from '../reports/services/report.service';
import { IResponse } from 'src/app/core/interfaces/IResponse';
import { AbsError } from 'src/app/core/error/AbsError';
import { NavigationEnd, Router } from '@angular/router';
import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { UserIntegrationView } from 'src/app/core/models/UserIntegrationView';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  animations: [
    // Here is the animation for sliding in/out
    trigger('menuAnimation', [
      state(
        'open',
        style({
          transform: 'translateX(0)',
        })
      ),
      state(
        'closed',
        style({
          transform: 'translateX(-100%)',
        })
      ),
      transition('open <=> closed', [animate('300ms ease-in-out')]),
    ]),
  ],
})
export class DashboardComponent extends BasePage implements OnInit, OnDestroy {
  user!: UserView;
  modalData: any = null;
  modalName: string = '';
  reportNum: number = 0;
  private modalSubscription!: Subscription;
  private urlSubscription!: Subscription;
  mobileMenuOpen = false;
  userIntegration: UserIntegrationView = new UserIntegrationView();
  editConfigurationClicked: boolean = false;
  editDirectConfigurationClicked: boolean = false;

  constructor(
    _redirectService: RedirectService,
    _userService: UserService,
    _alert: AlertNotificationsService,
    private router: Router,
    private _clerkService: ClerkService,
    private _modalCommunicationService: ModalCommunicationService,
    private _modalService: ModalService,
    private _reportService: ReportService
  ) {
    super(_alert, _userService, _redirectService);
  }

  ngOnInit(): void {
    this.user = this.getUser();
    this.modalSubscription =
      this._modalCommunicationService.modalAction$.subscribe(
        ({ modalName, data }) => {
          this.modalData = data;
          this.modalName = modalName;
          this.mapIntegrationFields();
          this.openModal();
        }
      );

    this.urlSubscription = this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        this.closeMobileMenu();
        this.getAllUserReports();
        this.getUserFromDb();
      });

    this.getAllUserReports();
  }

  async getUserFromDb(): Promise<void> {
    try {
      const resp: IResponse = await firstValueFrom(
        this._userService.getUserById(this.user.id)
      );

      if (!resp.success) {
        throw new AbsError(resp.message);
      }

      this.user = resp.data;
      this._userService.saveUserToLocalStorage(this.user);
    } catch (error) {
      this.handleError(error);
    }
  }

  async getAllUserReports(): Promise<void> {
    try {
      const resp: IResponse = await firstValueFrom(
        this._reportService.getAllReports(this.user.id)
      );

      if (!resp.success) {
        throw new AbsError(resp.message);
      }

      this.reportNum = resp.data.length;
    } catch (error) {
      this.handleError(error);
    }
  }

  openModal(): void {
    this._modalService.open(this.modalName);
  }

  closeModal(): void {
    this._modalService.close(this.modalName);
  }

  removeText(): void {
    if (this.modalData) {
      this._modalCommunicationService.confirmTextDelete(this.modalData);
      this.closeModal();
    }
  }

  removeQuestion(): void {
    if (this.modalData) {
      this._modalCommunicationService.confirmQuestionDelete(this.modalData);
      this.closeModal();
    }
  }

  removeDocument(): void {
    if (this.modalData) {
      this._modalCommunicationService.confirmDocumentDelete(this.modalData);
      this.closeModal();
    }
  }

  removeFlaggedQuestion(): void {
    if (this.modalData) {
      this._modalCommunicationService.confirmFlaggedQuestionDelete(
        this.modalData
      );
      this.closeModal();
    }
  }

  removeConfiguration(): void {
    if (this.modalData) {
      this._modalCommunicationService.confirmIntegrationDelete(this.modalData);
      this.closeModal();
    }
  }

  editConfiguration(): void {
    this.editConfigurationClicked = true;
    this._modalCommunicationService.confirmIntegrationEdit(
      this.userIntegration
    );
    this.closeModal();
    this.editConfigurationClicked = false;
  }

  editDirectConfiguration(): void {
    this.editDirectConfigurationClicked = true;
    this._modalCommunicationService.confirmDirectIntegrationEdit(
      this.userIntegration
    );
    this.closeModal();
    this.editDirectConfigurationClicked = false;
  }

  ngOnDestroy(): void {
    if (this.modalSubscription) {
      this.modalSubscription.unsubscribe();
    }
    if (this.urlSubscription) {
      this.urlSubscription.unsubscribe();
    }
  }

  logout(): void {
    this._clerkService.signOut();
  }

  toggleMobileMenu() {
    this.mobileMenuOpen = !this.mobileMenuOpen;
  }

  closeMobileMenu() {
    this.mobileMenuOpen = false;
  }

  removeDirectConfiguration(): void {
    if (this.modalData) {
      this._modalCommunicationService.confirmDirectIntegrationDelete(
        this.modalData
      );
      this.closeModal();
    }
  }

  mapIntegrationFields(): void {
    if (this.modalData.configuration_name) {
      this.userIntegration.configuration_name =
        this.modalData.configuration_name;
    }

    if (this.modalData.bot_token) {
      this.userIntegration.bot_token = this.modalData.bot_token;
    }

    if (this.modalData.webhook_token) {
      this.userIntegration.webhook_token = this.modalData.webhook_token;
    }

    if (this.modalData.mattermost_server_url) {
      this.userIntegration.mattermost_server_url =
        this.modalData.mattermost_server_url;
    }

    if (this.modalData.id) {
      this.userIntegration.id = this.modalData.id;
    }

    if (this.modalData.user_id) {
      this.userIntegration.user_id = this.modalData.user_id;
    }

    if (this.modalData.command_slash_token) {
      this.userIntegration.command_slash_token =
        this.modalData.command_slash_token;
    }

    if (this.modalData.is_public_integration) {
      this.userIntegration.is_public_integration =
        this.modalData.is_public_integration;
    }
  }
}
