import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';
import { UserView } from '../../models/UserView';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private API_URL = environment.backend_api_url;
  private userSubject!: BehaviorSubject<UserView | null>;
  public user$!: Observable<UserView | null>;

  constructor(
    private _http: HttpClient,
    @Inject(PLATFORM_ID)
    private platformId: any
  ) {
    const storedUser = this.getUserFromLocalStorage();
    this.userSubject = new BehaviorSubject<UserView | null>(storedUser);
    this.user$ = this.userSubject.asObservable();
  }

  addUser(user: UserView): Observable<any> {
    return this._http.post<any>(
      this.API_URL + '/user',
      { user: user },
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        }),
      }
    );
  }

  findUsers(username: string): Observable<any> {
    return this._http.get<any>(
      this.API_URL + '/users/' + username,

      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        }),
      }
    );
  }

  getUserById(user_id: number): Observable<any> {
    return this._http.get<any>(
      this.API_URL + '/user/' + user_id,

      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        }),
      }
    );
  }

  getUserGameMode(gameModeId: Number): Observable<any> {
    return this._http.get<any>(
      this.API_URL + '/user/game-mode/' + gameModeId,

      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        }),
      }
    );
  }

  saveUserToLocalStorage(user: UserView): void {
    if (isPlatformBrowser(this.platformId)) {
      localStorage.setItem('matterhelp_user', JSON.stringify(user));
      this.userSubject.next(user);
    }
  }

  getUserFromLocalStorage(): UserView | null {
    if (isPlatformBrowser(this.platformId)) {
      const storedUser = localStorage.getItem('matterhelp_user');
      if (storedUser) {
        return JSON.parse(storedUser) as UserView;
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  modifyUser(user: UserView): Observable<any> {
    return this._http.put<any>(
      this.API_URL + '/user',
      { user: user },
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        }),
      }
    );
  }

  getUser(): UserView | null {
    return this.userSubject.value;
  }
}
