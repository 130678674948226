import { AlertType } from '../enums/AlertType.enum';

export class AlertView {
  id!: string;
  type!: AlertType;
  message!: string;
  autoClose: boolean = true;
  keepAfterRouteChange: boolean = true;
  fade: boolean = true;

  constructor(init?: Partial<AlertView>) {
    Object.assign(this, init);
  }
}
