import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  private modals: { [id: string]: BehaviorSubject<boolean> } = {};

  constructor() {}

  register(id: string) {
    if (!this.modals[id]) {
      this.modals[id] = new BehaviorSubject<boolean>(false);
    }
  }

  open(id: string) {
    if (this.modals[id]) {
      this.modals[id].next(true);
    }
  }

  close(id: string) {
    if (this.modals[id]) {
      this.modals[id].next(false);
    }
  }

  isOpen$(id: string): Observable<boolean> {
    return this.modals[id]
      ? this.modals[id].asObservable()
      : new BehaviorSubject<boolean>(false).asObservable();
  }
}
