import { AbsError } from '../error/AbsError';
import { UserView } from '../models/UserView';
import { AlertNotificationsService } from '../services/alerts/alert-notifications.service';
import { UserService } from '../services/user/user.service';
import { RedirectService } from '../services/redirect/redirect.service';
import { Pages } from '../enums/Pages.enum';
import { ElementRef } from '@angular/core';

export class BasePage {
  fadeInTriggered = false;
  constructor(
    public _alert: AlertNotificationsService,
    public _userService: UserService,
    public _redirectService: RedirectService
  ) {}

  handleError(error: any): void {
    if (error instanceof AbsError) {
      this._alert.error(error.message);
    } else {
      this._alert.error('Wooops! There was a mistake.');
    }
  }

  getUser(): UserView {
    let user: UserView | null = this._userService.getUser();

    if (user) {
      return user;
    } else {
      this._redirectService.redirectRoute(Pages.LOGIN);
      throw new Error('Redirecting to login, user is not authenticated.');
    }
  }

  observeFadeInElement(fadeInElement: ElementRef): void {
    if (!this.fadeInTriggered) {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && !this.fadeInTriggered) {
            this.fadeInTriggered = true;
            observer.unobserve(fadeInElement.nativeElement);
          }
        });
      });

      setTimeout(() => {
        observer.observe(fadeInElement.nativeElement);
      }, 0);
    }
  }
}
