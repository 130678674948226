export class UserIntegrationView {
  id: number;
  configuration_name: string;
  user_id: number;
  bot_token: string;
  webhook_token: string;
  mattermost_server_url: string;
  command_slash_token: string;
  is_public_integration: boolean;

  constructor(
    data: {
      id?: number;
      configuration_name?: string;
      user_id?: number;
      bot_token?: string;
      webhook_token?: string;
      mattermost_server_url?: string;
      command_slash_token?: string;
      is_public_integration?: boolean;
    } = {}
  ) {
    this.id = data.id ?? 0;
    this.configuration_name = data.configuration_name ?? '';
    this.user_id = data.user_id ?? 0;
    this.bot_token = data.bot_token ?? '';
    this.webhook_token = data.webhook_token ?? '';
    this.mattermost_server_url = data.mattermost_server_url ?? '';
    this.command_slash_token = data.command_slash_token ?? '';
    this.is_public_integration = data.is_public_integration ?? false;
  }
}
