import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router'; // Required for routerLink
import { NavbarComponent } from '../components/navbar/navbar.component';
import { FooterComponent } from '../components/footer/footer.component';
@NgModule({
  declarations: [NavbarComponent, FooterComponent], // Declaring NavbarComponent
  imports: [
    CommonModule,
    RouterModule, // RouterModule is needed for routerLink
  ],
  exports: [NavbarComponent, FooterComponent], // Exporting NavbarComponent
})
export class WebSharedModule {}
