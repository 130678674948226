import { Component, Input, OnInit } from '@angular/core';
import { AlertView } from '../../models/AlertView';
import { NavigationStart, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AlertNotificationsService } from '../../services/alerts/alert-notifications.service';

@Component({
  selector: 'app-alert-notifications',
  templateUrl: './alert-notifications.component.html',
  styleUrls: ['./alert-notifications.component.scss'],
})
export class AlertNotificationsComponent implements OnInit {
  @Input() id = 'default-alert';
  @Input() fade = true;

  alerts: AlertView[] = [];
  alertSubscription!: Subscription;
  routeSubscription!: Subscription;

  constructor(
    private _alert: AlertNotificationsService,
    private _router: Router
  ) {}

  ngOnInit() {
    // subscribe to new alert notifications
    this.alertSubscription = this._alert.onAlert(this.id).subscribe((alert) => {
      // clear alerts when an empty alert is received
      if (!alert.message) {
        // filter out alerts without 'keepAfterRouteChange' flag
        this.alerts = this.alerts.filter((x) => x.keepAfterRouteChange);

        // remove 'keepAfterRouteChange' flag on the rest
        this.alerts.forEach((x: any) => delete x.keepAfterRouteChange);
        return;
      }

      // add alert to array
      this.alerts.push(alert);

      // auto close alert if required
      if (alert.autoClose) {
        setTimeout(() => this.removeAlert(alert), 3000);
      }
    });

    // clear alerts on location change
    this.routeSubscription = this._router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this._alert.clear(this.id);
      }
    });
  }

  ngOnDestroy() {
    // unsubscribe to avoid memory leaks
    this.alertSubscription.unsubscribe();
    this.routeSubscription.unsubscribe();
  }

  removeAlert(alert: AlertView) {
    // check if already removed to prevent error on auto close
    if (!this.alerts.includes(alert)) return;

    if (this.fade) {
      // fade out alert
      //this.alerts.find((x) => x === alert).fade = true;

      // remove alert after faded out
      setTimeout(() => {
        this.alerts = this.alerts.filter((x) => x !== alert);
      }, 250);
    } else {
      // remove alert
      this.alerts = this.alerts.filter((x) => x !== alert);
    }
  }
}
