<div class="bg-[#f4f7fe]">
  <!-- Off-canvas menu for mobile, show/hide based on off-canvas menu state. -->
  <div
    class="relative z-50 lg:hidden"
    role="dialog"
    aria-modal="true"
    [class.hidden]="!mobileMenuOpen"
  >
    <!-- Backdrop -->
    <div
      *ngIf="mobileMenuOpen"
      class="fixed inset-0 bg-black opacity-50"
      (mousedown)="closeMobileMenu()"
      aria-hidden="true"
    ></div>

    <!-- Off-canvas menu -->
    <div
      class="fixed inset-0 flex transition ease-in-out duration-300"
      [@menuAnimation]="mobileMenuOpen ? 'open' : 'closed'"
    >
      <div class="relative mr-16 flex w-full max-w-xs flex-1">
        <div class="absolute left-full top-0 flex w-16 justify-center pt-5">
          <button
            type="button"
            class="-m-2.5 p-2.5"
            (mousedown)="closeMobileMenu()"
          >
            <span class="sr-only">Close sidebar</span>
            <svg
              class="h-6 w-6 text-white"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>

        <!-- Sidebar component -->
        <div
          class="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 pb-2"
        >
          <div class="flex h-16 shrink-0 items-center">
            <div class="flex gap-4 items-center">
              <img
                class="h-8 w-auto"
                src="../../../assets/logo-icon.svg"
                alt="Matterhelp"
              />
              <h1
                class="font-bold text-lg text-gray-900 tracking-wider leading-relaxed"
              >
                Matterhelp
              </h1>
            </div>
          </div>
          <nav class="flex flex-1 flex-col">
            <ul role="list" class="flex flex-1 flex-col gap-y-7">
              <li>
                <ul role="list" class="-mx-2 space-y-4">
                  <div>
                    <p class="text-xs mb-2">Test Your Data</p>
                    <li>
                      <a
                        [routerLink]="['/dashboard/chat']"
                        routerLinkActive="bg-violet-200"
                        class="group flex gap-x-3 rounded-md bg-gray-50 p-2 text-sm font-semibold leading-6 text-indigo-600"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="size-6"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M20.25 8.511c.884.284 1.5 1.128 1.5 2.097v4.286c0 1.136-.847 2.1-1.98 2.193-.34.027-.68.052-1.02.072v3.091l-3-3c-1.354 0-2.694-.055-4.02-.163a2.115 2.115 0 0 1-.825-.242m9.345-8.334a2.126 2.126 0 0 0-.476-.095 48.64 48.64 0 0 0-8.048 0c-1.131.094-1.976 1.057-1.976 2.192v4.286c0 .837.46 1.58 1.155 1.951m9.345-8.334V6.637c0-1.621-1.152-3.026-2.76-3.235A48.455 48.455 0 0 0 11.25 3c-2.115 0-4.198.137-6.24.402-1.608.209-2.76 1.614-2.76 3.235v6.226c0 1.621 1.152 3.026 2.76 3.235.577.075 1.157.14 1.74.194V21l4.155-4.155"
                          />
                        </svg>
                        Chat
                      </a>
                    </li>
                  </div>

                  <div>
                    <p class="text-xs mt-5 mb-2">Knowledge base</p>
                    <li>
                      <a
                        [routerLink]="['/dashboard/document']"
                        routerLinkActive="bg-violet-200"
                        class="group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50 hover:text-indigo-600"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="size-6"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m6.75 12-3-3m0 0-3 3m3-3v6m-1.5-15H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z"
                          />
                        </svg>
                        Document
                      </a>
                    </li>
                    <li>
                      <a
                        [routerLink]="['/dashboard/text']"
                        routerLinkActive="bg-violet-200"
                        class="group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50 hover:text-indigo-600"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="size-6"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z"
                          />
                        </svg>
                        Text
                      </a>
                    </li>
                    <li>
                      <a
                        [routerLink]="['/dashboard/q-and-a']"
                        routerLinkActive="bg-violet-200"
                        class="group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50 hover:text-indigo-600"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="size-6"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 5.25h.008v.008H12v-.008Z"
                          />
                        </svg>
                        Q&A
                      </a>
                    </li>
                  </div>

                  <div>
                    <p class="text-xs mt-5 mb-2">Integration</p>
                    <li>
                      <a
                        [routerLink]="['/dashboard/connect']"
                        routerLinkActive="bg-violet-200"
                        class="group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50 hover:text-indigo-600"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="size-6"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M11.42 15.17 17.25 21A2.652 2.652 0 0 0 21 17.25l-5.877-5.877M11.42 15.17l2.496-3.03c.317-.384.74-.626 1.208-.766M11.42 15.17l-4.655 5.653a2.548 2.548 0 1 1-3.586-3.586l6.837-5.63m5.108-.233c.55-.164 1.163-.188 1.743-.14a4.5 4.5 0 0 0 4.486-6.336l-3.276 3.277a3.004 3.004 0 0 1-2.25-2.25l3.276-3.276a4.5 4.5 0 0 0-6.336 4.486c.091 1.076-.071 2.264-.904 2.95l-.102.085m-1.745 1.437L5.909 7.5H4.5L2.25 3.75l1.5-1.5L7.5 4.5v1.409l4.26 4.26m-1.745 1.437 1.745-1.437m6.615 8.206L15.75 15.75M4.867 19.125h.008v.008h-.008v-.008Z"
                          />
                        </svg>
                        Connect
                      </a>
                    </li>
                  </div>

                  <div>
                    <p class="text-xs mt-5 mb-2">Other</p>
                    <li>
                      <a
                        [routerLink]="['/dashboard/reports']"
                        routerLinkActive="bg-violet-200"
                        class="group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50 hover:text-indigo-600"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="size-6"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M3.75 3v11.25A2.25 2.25 0 0 0 6 16.5h2.25M3.75 3h-1.5m1.5 0h16.5m0 0h1.5m-1.5 0v11.25A2.25 2.25 0 0 1 18 16.5h-2.25m-7.5 0h7.5m-7.5 0-1 3m8.5-3 1 3m0 0 .5 1.5m-.5-1.5h-9.5m0 0-.5 1.5M9 11.25v1.5M12 9v3.75m3-6v6"
                          />
                        </svg>
                        Reports

                        <span
                          *ngIf="reportNum > 0"
                          class="inline-flex items-center rounded-full bg-red-500 px-2.5 py-0.5 text-xs font-medium text-white"
                        >
                          {{ reportNum }}
                        </span>
                      </a>
                    </li>

                    <li>
                      <a
                        [routerLink]="['/dashboard/balance']"
                        routerLinkActive="bg-violet-200"
                        class="group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50 hover:text-indigo-600"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="size-6"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M2.25 8.25h19.5M2.25 9h19.5m-16.5 5.25h6m-6 2.25h3m-3.75 3h15a2.25 2.25 0 0 0 2.25-2.25V6.75A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25v10.5A2.25 2.25 0 0 0 4.5 19.5Z"
                          />
                        </svg>
                        Add funds
                      </a>
                    </li>
                  </div>

                  <!-- More items... -->
                </ul>
              </li>
            </ul>
          </nav>

          <div class="flex pb-7">
            <div class="flex-shrink-0">
              <img
                class="w-12 h-12 rounded-full"
                [src]="user.picture"
                alt="Profile Picture"
              />
            </div>
            <div class="ml-4">
              <div class="text-lg font-semibold text-gray-800">
                {{ user.first_name }} {{ user.last_name }}
              </div>
              <div class="text-sm text-gray-600">
                Balance: ${{ user.balance | number : "1.2-2" }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Static sidebar for desktop -->
  <div class="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
    <div
      class="flex grow flex-col overflow-y-auto border-r border-gray-200 bg-white px-6 text-gray-700"
    >
      <div class="flex flex-row items-center justify-between">
        <div class="flex h-16 shrink-0 items-center">
          <div class="flex gap-4 items-center">
            <img
              class="h-8 w-auto"
              src="../../../assets/logo-icon.svg"
              alt="Matterhelp"
            />
            <h1
              class="font-bold text-lg text-gray-900 tracking-wider leading-relaxed"
            >
              Matterhelp
            </h1>
          </div>
        </div>
        <button
          class="rounded-full flex justify-center items-center h-10 w-10 hover:bg-gray-200"
          (mousedown)="logout()"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            class="size-6"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M8.25 9V5.25A2.25 2.25 0 0 1 10.5 3h6a2.25 2.25 0 0 1 2.25 2.25v13.5A2.25 2.25 0 0 1 16.5 21h-6a2.25 2.25 0 0 1-2.25-2.25V15m-3 0-3-3m0 0 3-3m-3 3H15"
            />
          </svg>
        </button>
      </div>

      <div class="flex flex-col justify-between flex-1">
        <div class="gap-y-4">
          <div>
            <p class="text-xs mt-5 mb-2">Test Your Data</p>
            <nav class="flex flex-1 flex-col">
              <ul role="list" class="flex flex-1 flex-col gap-y-7">
                <li>
                  <ul role="list" class="-mx-2 space-y-1">
                    <li>
                      <a
                        [routerLink]="['/dashboard/chat']"
                        routerLinkActive="bg-violet-200"
                        class="group flex gap-x-3 rounded-md p-2 transition-all text-sm font-medium pl-1.5 hover:pl-3.5 -ml-1.5 leading-6 text-indigo-900 hover:bg-violet-200"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="size-6"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M20.25 8.511c.884.284 1.5 1.128 1.5 2.097v4.286c0 1.136-.847 2.1-1.98 2.193-.34.027-.68.052-1.02.072v3.091l-3-3c-1.354 0-2.694-.055-4.02-.163a2.115 2.115 0 0 1-.825-.242m9.345-8.334a2.126 2.126 0 0 0-.476-.095 48.64 48.64 0 0 0-8.048 0c-1.131.094-1.976 1.057-1.976 2.192v4.286c0 .837.46 1.58 1.155 1.951m9.345-8.334V6.637c0-1.621-1.152-3.026-2.76-3.235A48.455 48.455 0 0 0 11.25 3c-2.115 0-4.198.137-6.24.402-1.608.209-2.76 1.614-2.76 3.235v6.226c0 1.621 1.152 3.026 2.76 3.235.577.075 1.157.14 1.74.194V21l4.155-4.155"
                          />
                        </svg>
                        Chat
                      </a>
                    </li>
                    <!-- More menu items here -->
                  </ul>
                </li>
              </ul>
            </nav>
          </div>

          <div>
            <p class="text-xs mt-5 mb-2">Knowledge base</p>
            <nav class="flex flex-1 flex-col">
              <ul role="list" class="flex flex-1 flex-col gap-y-7">
                <li>
                  <ul role="list" class="-mx-2 space-y-1">
                    <li>
                      <!-- Current: "bg-gray-50 text-indigo-600", Default: "text-gray-700 hover:text-indigo-600 hover:bg-gray-50" -->
                      <a
                        [routerLink]="['/dashboard/document']"
                        routerLinkActive="bg-violet-200"
                        class="group flex gap-x-3 rounded-md p-2 transition-all text-sm font-medium pl-1.5 hover:pl-3.5 -ml-1.5 leading-6 text-indigo-900 hover:bg-violet-200"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="size-6"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m6.75 12-3-3m0 0-3 3m3-3v6m-1.5-15H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z"
                          />
                        </svg>

                        Document
                      </a>
                    </li>
                    <li>
                      <a
                        [routerLink]="['/dashboard/text']"
                        routerLinkActive="bg-violet-200"
                        class="group flex gap-x-3 rounded-md p-2 transition-all text-sm font-medium pl-1.5 hover:pl-3.5 -ml-1.5 leading-6 text-indigo-900 hover:bg-violet-200"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="size-6"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z"
                          />
                        </svg>

                        Text
                      </a>
                    </li>
                    <li>
                      <a
                        [routerLink]="['/dashboard/q-and-a']"
                        routerLinkActive="bg-violet-200"
                        class="group flex gap-x-3 rounded-md p-2 transition-all text-sm font-medium pl-1.5 hover:pl-3.5 -ml-1.5 leading-6 text-indigo-900 hover:bg-violet-200"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="size-6"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 5.25h.008v.008H12v-.008Z"
                          />
                        </svg>

                        Q&A
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </nav>
          </div>

          <div>
            <p class="text-xs mt-5 mb-2">Integration</p>
            <nav class="flex flex-1 flex-col">
              <ul role="list" class="flex flex-1 flex-col gap-y-7">
                <li>
                  <ul role="list" class="-mx-2 space-y-1">
                    <li>
                      <!-- Current: "bg-gray-50 text-indigo-600", Default: "text-gray-700 hover:text-indigo-600 hover:bg-gray-50" -->
                      <a
                        [routerLink]="['/dashboard/connect']"
                        routerLinkActive="bg-violet-200"
                        class="group flex gap-x-3 rounded-md p-2 transition-all text-sm font-medium pl-1.5 hover:pl-3.5 -ml-1.5 leading-6 text-indigo-900 hover:bg-violet-200"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="size-6"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M11.42 15.17 17.25 21A2.652 2.652 0 0 0 21 17.25l-5.877-5.877M11.42 15.17l2.496-3.03c.317-.384.74-.626 1.208-.766M11.42 15.17l-4.655 5.653a2.548 2.548 0 1 1-3.586-3.586l6.837-5.63m5.108-.233c.55-.164 1.163-.188 1.743-.14a4.5 4.5 0 0 0 4.486-6.336l-3.276 3.277a3.004 3.004 0 0 1-2.25-2.25l3.276-3.276a4.5 4.5 0 0 0-6.336 4.486c.091 1.076-.071 2.264-.904 2.95l-.102.085m-1.745 1.437L5.909 7.5H4.5L2.25 3.75l1.5-1.5L7.5 4.5v1.409l4.26 4.26m-1.745 1.437 1.745-1.437m6.615 8.206L15.75 15.75M4.867 19.125h.008v.008h-.008v-.008Z"
                          />
                        </svg>

                        Connect
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </nav>
          </div>

          <div>
            <p class="text-xs mt-5 mb-2">Other</p>
            <nav class="flex flex-1 flex-col">
              <ul role="list" class="flex flex-1 flex-col gap-y-7">
                <li>
                  <ul role="list" class="-mx-2 space-y-1">
                    <li>
                      <a
                        [routerLink]="['/dashboard/reports']"
                        routerLinkActive="bg-violet-200"
                        class="group flex gap-x-3 rounded-md p-2 transition-all text-sm font-medium pl-1.5 hover:pl-3.5 -ml-1.5 leading-6 text-indigo-900 hover:bg-violet-200"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="size-6"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M3.75 3v11.25A2.25 2.25 0 0 0 6 16.5h2.25M3.75 3h-1.5m1.5 0h16.5m0 0h1.5m-1.5 0v11.25A2.25 2.25 0 0 1 18 16.5h-2.25m-7.5 0h7.5m-7.5 0-1 3m8.5-3 1 3m0 0 .5 1.5m-.5-1.5h-9.5m0 0-.5 1.5M9 11.25v1.5M12 9v3.75m3-6v6"
                          />
                        </svg>

                        Reports

                        <span
                          *ngIf="reportNum > 0"
                          class="inline-flex items-center rounded-full bg-red-500 px-2.5 py-0.5 text-xs font-medium text-white"
                        >
                          {{ reportNum }}
                        </span>
                      </a>
                    </li>
                    <li>
                      <a
                        [routerLink]="['/dashboard/balance']"
                        routerLinkActive="bg-violet-200"
                        class="group flex gap-x-3 rounded-md p-2 transition-all text-sm font-medium pl-1.5 hover:pl-3.5 -ml-1.5 leading-6 text-indigo-900 hover:bg-violet-200"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="size-6"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M2.25 8.25h19.5M2.25 9h19.5m-16.5 5.25h6m-6 2.25h3m-3.75 3h15a2.25 2.25 0 0 0 2.25-2.25V6.75A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25v10.5A2.25 2.25 0 0 0 4.5 19.5Z"
                          />
                        </svg>

                        Add funds
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </nav>
          </div>
        </div>

        <!-- Sidebar footer with profile picture and balance -->
        <div class="flex pb-7">
          <div class="flex-shrink-0">
            <img
              class="w-12 h-12 rounded-full"
              [src]="user.picture"
              alt="Profile Picture"
            />
          </div>
          <div class="ml-4">
            <div class="text-lg font-semibold text-gray-800">
              {{ user.first_name }} {{ user.last_name }}
            </div>
            <div class="text-sm text-gray-600">
              Balance: ${{ user.balance | number : "1.2-2" }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Mobile top bar with button to open menu -->
  <div
    class="sticky top-0 z-40 flex items-center gap-x-6 bg-white px-4 py-4 shadow-sm sm:px-6 lg:hidden"
  >
    <button
      type="button"
      class="-m-2.5 p-2.5 text-gray-700 lg:hidden"
      (mousedown)="toggleMobileMenu()"
    >
      <span class="sr-only">Open sidebar</span>
      <svg
        class="h-6 w-6"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        aria-hidden="true"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
        />
      </svg>
    </button>
    <div class="flex-1 text-sm font-semibold leading-6 text-gray-900">
      Dashboard
    </div>
  </div>

  <main class="lg:pl-72 min-h-screen bg-[#f4f7fe]">
    <div class="px-4 sm:px-6 lg:px-8">
      <router-outlet></router-outlet>
    </div>
  </main>
</div>

<app-modal [width]="'450px'" [height]="'350px'" [id]="'deleteTextModal'">
  <div class="relative w-full h-full">
    <div
      class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:w-full sm:max-w-lg sm:p-6"
    >
      <div class="sm:flex sm:items-start">
        <div
          class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10"
        >
          <svg
            class="h-6 w-6 text-red-600"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
            />
          </svg>
        </div>
        <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
          <h3
            class="text-base font-semibold leading-6 text-gray-900"
            id="modal-title"
          >
            Delete text data
          </h3>
          <div class="mt-2">
            <p class="text-sm text-gray-500">
              Are you sure you want to delete this text data
              <span class="font-bold">{{ modalData?.title }}</span
              >? This data will be permanently removed from our servers forever.
              This action cannot be undone.
            </p>
          </div>
        </div>
      </div>
      <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
        <button
          type="button"
          (mousedown)="removeText()"
          class="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
        >
          Remove
        </button>
        <button
          type="button"
          (mousedown)="closeModal()"
          class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
        >
          Cancel
        </button>
      </div>
    </div>
  </div>
</app-modal>

<app-modal [width]="'450px'" [height]="'350px'" [id]="'deleteQuestionModal'">
  <div class="relative w-full h-full">
    <div
      class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:w-full sm:max-w-lg sm:p-6"
    >
      <div class="sm:flex sm:items-start">
        <div
          class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10"
        >
          <svg
            class="h-6 w-6 text-red-600"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
            />
          </svg>
        </div>
        <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
          <h3
            class="text-base font-semibold leading-6 text-gray-900"
            id="modal-title"
          >
            Delete questions & answers data
          </h3>
          <div class="mt-2">
            <p class="text-sm text-gray-500">
              Are you sure you want to delete this question
              <span class="font-bold">{{ modalData?.question }}</span
              >? This data will be permanently removed from our servers forever.
              This action cannot be undone.
            </p>
          </div>
        </div>
      </div>
      <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
        <button
          type="button"
          (mousedown)="removeQuestion()"
          class="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
        >
          Remove
        </button>
        <button
          type="button"
          (mousedown)="closeModal()"
          class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
        >
          Cancel
        </button>
      </div>
    </div>
  </div>
</app-modal>

<app-modal [width]="'450px'" [height]="'350px'" [id]="'deleteDocumentModal'">
  <div class="relative w-full h-full">
    <div
      class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:w-full sm:max-w-lg sm:p-6"
    >
      <div class="sm:flex sm:items-start">
        <div
          class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10"
        >
          <svg
            class="h-6 w-6 text-red-600"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
            />
          </svg>
        </div>
        <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
          <h3
            class="text-base font-semibold leading-6 text-gray-900"
            id="modal-title"
          >
            Delete document data
          </h3>
          <div class="mt-2">
            <p class="text-sm text-gray-500">
              Are you sure you want to delete this document
              <span class="font-bold">{{ modalData?.title }}</span
              >? This data will be permanently removed from our servers forever.
              This action cannot be undone.
            </p>
          </div>
        </div>
      </div>
      <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
        <button
          type="button"
          (mousedown)="removeDocument()"
          class="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
        >
          Remove
        </button>
        <button
          type="button"
          (mousedown)="closeModal()"
          class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
        >
          Cancel
        </button>
      </div>
    </div>
  </div>
</app-modal>

<!-- delete report modal-->
<app-modal [width]="'450px'" [height]="'350px'" [id]="'deleteReportModal'">
  <div class="relative w-full h-full">
    <div
      class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:w-full sm:max-w-lg sm:p-6"
    >
      <div class="sm:flex sm:items-start">
        <div
          class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10"
        >
          <svg
            class="h-6 w-6 text-red-600"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
            />
          </svg>
        </div>
        <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
          <h3
            class="text-base font-semibold leading-6 text-gray-900"
            id="modal-title"
          >
            Delete flagged question
          </h3>
          <div class="mt-2">
            <p class="text-sm text-gray-500">
              Are you sure you want to delete this flagged question
              <span class="font-bold">{{ modalData?.question }}</span
              >? This data will be permanently removed from our servers forever.
              This action cannot be undone.
            </p>
          </div>
        </div>
      </div>
      <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
        <button
          type="button"
          (mousedown)="removeFlaggedQuestion()"
          class="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
        >
          Remove
        </button>
        <button
          type="button"
          (mousedown)="closeModal()"
          class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
        >
          Cancel
        </button>
      </div>
    </div>
  </div>
</app-modal>

<!-- view report modal-->
<app-modal [width]="'450px'" [height]="'auto'" [id]="'viewReportModal'">
  <div class="relative w-full h-full">
    <div
      class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:w-full sm:max-w-lg sm:p-6"
    >
      <div class="sm:flex sm:items-start">
        <div
          class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10"
        >
          <svg
            class="h-6 w-6 text-red-600"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
            />
          </svg>
        </div>
        <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
          <h3
            class="text-base font-semibold leading-6 text-gray-900"
            id="modal-title"
          >
            Flagged question data
          </h3>
          <div class="mt-2">
            <p class="text-sm text-gray-500">
              You are about to view the details of a flagged question. Review
              the content before proceeding.
            </p>
          </div>

          <!-- Displaying Question and Answer -->
          <div class="mt-4 bg-gray-100 p-4 rounded-md">
            <p class="font-semibold text-gray-900">Question:</p>
            <p class="text-sm text-gray-700 mt-1">{{ modalData?.question }}</p>
          </div>

          <div class="mt-4 bg-gray-100 p-4 rounded-md">
            <p class="font-semibold text-gray-900">Answer:</p>
            <p class="text-sm text-gray-700 mt-1">{{ modalData?.answer }}</p>
          </div>
        </div>
      </div>
      <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
        <button
          type="button"
          (mousedown)="closeModal()"
          class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
        >
          Close
        </button>
      </div>
    </div>
  </div>
</app-modal>

<!-- delete user configuration modal -->
<app-modal
  [width]="'450px'"
  [height]="'350px'"
  [id]="'deleteUserIntegrationModal'"
>
  <div class="relative w-full h-full">
    <div
      class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:w-full sm:max-w-lg sm:p-6"
    >
      <div class="sm:flex sm:items-start">
        <div
          class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10"
        >
          <svg
            class="h-6 w-6 text-red-600"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
            />
          </svg>
        </div>
        <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
          <h3
            class="text-base font-semibold leading-6 text-gray-900"
            id="modal-title"
          >
            Delete Configuration
          </h3>
          <div class="mt-2">
            <p class="text-sm text-gray-500">
              Are you sure you want to delete this configuration
              <span class="font-bold">{{ modalData?.configuration_name }}</span
              >? This data will be permanently removed from our servers forever.
              This action cannot be undone.
            </p>
          </div>
        </div>
      </div>
      <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
        <button
          type="button"
          (mousedown)="removeConfiguration()"
          class="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
        >
          Remove
        </button>
        <button
          type="button"
          (mousedown)="closeModal()"
          class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
        >
          Cancel
        </button>
      </div>
    </div>
  </div>
</app-modal>

<!-- edit user configuration modal -->
<app-modal
  [width]="'450px'"
  [height]="'auto'"
  [id]="'editUserIntegrationModal'"
>
  <div class="relative w-full h-full">
    <div
      class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:w-full sm:max-w-lg sm:p-6"
    >
      <div class="sm:flex sm:items-start">
        <div
          class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10"
        >
          <svg
            class="h-6 w-6 text-red-600"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
            />
          </svg>
        </div>
        <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left w-full">
          <h3
            class="text-base font-semibold leading-6 text-gray-900"
            id="modal-title"
          >
            Edit Configuration
          </h3>
          <div class="mt-4 w-full">
            <!-- Add the ngSubmit event to call editConfiguration -->
            <form
              #editUserIntegrationForm="ngForm"
              (ngSubmit)="editConfiguration()"
              class="space-y-4"
            >
              <!-- Configuration Name Input -->
              <div>
                <label
                  for="configuration_name"
                  class="block text-sm font-medium text-gray-700"
                >
                  Configuration Name
                </label>
                <div class="mt-2">
                  <input
                    id="configuration_name"
                    name="configuration_name"
                    [(ngModel)]="userIntegration.configuration_name"
                    #configuration_name="ngModel"
                    type="text"
                    required
                    class="block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    placeholder="Enter configuration name"
                  />
                  <!-- Error message for configuration name -->
                  <div
                    *ngIf="
                      configuration_name.invalid && configuration_name.touched
                    "
                    class="text-red-500 text-xs mt-0.5 ml-0.5"
                  >
                    Configuration name is required.
                  </div>
                </div>
              </div>

              <!-- Bot Token Input -->
              <div>
                <label
                  for="bot_token"
                  class="block text-sm font-medium text-gray-700"
                  >Bot Token</label
                >
                <div class="mt-2">
                  <input
                    id="bot_token"
                    name="bot_token"
                    [(ngModel)]="userIntegration.bot_token"
                    #bot_token="ngModel"
                    type="text"
                    required
                    class="block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    placeholder="Enter your bot token"
                  />
                  <!-- Error message for bot token -->
                  <div
                    *ngIf="bot_token.invalid && bot_token.touched"
                    class="text-red-500 text-xs mt-0.5 ml-0.5"
                  >
                    Bot token is required.
                  </div>
                </div>
              </div>

              <!-- Webhook Token Input -->
              <div>
                <label
                  for="webhook_token"
                  class="block text-sm font-medium text-gray-700"
                  >Outgoing Webhook Token</label
                >
                <div class="mt-2">
                  <input
                    id="webhook_token"
                    name="webhook_token"
                    [(ngModel)]="userIntegration.webhook_token"
                    #webhook_token="ngModel"
                    type="text"
                    required
                    class="block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    placeholder="Enter outgoing webhook token"
                  />
                  <!-- Error message for webhook token -->
                  <div
                    *ngIf="webhook_token.invalid && webhook_token.touched"
                    class="text-red-500 text-xs mt-0.5 ml-0.5"
                  >
                    Webhook token is required.
                  </div>
                </div>
              </div>

              <!-- Mattermost Server URL Input -->
              <div>
                <label
                  for="mattermost_server_url"
                  class="block text-sm font-medium text-gray-700"
                  >Mattermost Site URL</label
                >
                <div class="mt-2">
                  <input
                    id="mattermost_server_url"
                    name="mattermost_server_url"
                    [(ngModel)]="userIntegration.mattermost_server_url"
                    #mattermost_server_url="ngModel"
                    type="url"
                    required
                    class="block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    placeholder="Enter Mattermost server URL"
                  />
                  <!-- Error message for Mattermost URL -->
                  <div
                    *ngIf="
                      mattermost_server_url.invalid &&
                      mattermost_server_url.touched
                    "
                    class="text-red-500 text-xs mt-0.5 ml-0.5"
                  >
                    Mattermost site URL is required.
                  </div>
                </div>
              </div>

              <!-- Submit and Cancel Buttons -->
              <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <button
                  type="submit"
                  [disabled]="
                    editUserIntegrationForm.invalid || editConfigurationClicked
                  "
                  [ngStyle]="{
                    opacity:
                      editUserIntegrationForm.invalid ||
                      editConfigurationClicked
                        ? '0.3'
                        : '1'
                  }"
                  class="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                >
                  Edit
                </button>
                <button
                  type="button"
                  (mousedown)="closeModal()"
                  class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</app-modal>

<!-- delete user direct configuration modal -->
<app-modal
  [width]="'450px'"
  [height]="'350px'"
  [id]="'deleteUserDirectIntegrationModal'"
>
  <div class="relative w-full h-full">
    <div
      class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:w-full sm:max-w-lg sm:p-6"
    >
      <div class="sm:flex sm:items-start">
        <div
          class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10"
        >
          <svg
            class="h-6 w-6 text-red-600"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
            />
          </svg>
        </div>
        <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
          <h3
            class="text-base font-semibold leading-6 text-gray-900"
            id="modal-title"
          >
            Delete Configuration
          </h3>
          <div class="mt-2">
            <p class="text-sm text-gray-500">
              Are you sure you want to delete this configuration
              <span class="font-bold">{{ modalData?.configuration_name }}</span
              >? This data will be permanently removed from our servers forever.
              This action cannot be undone.
            </p>
          </div>
        </div>
      </div>
      <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
        <button
          type="button"
          (mousedown)="removeDirectConfiguration()"
          class="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
        >
          Remove
        </button>
        <button
          type="button"
          (mousedown)="closeModal()"
          class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
        >
          Cancel
        </button>
      </div>
    </div>
  </div>
</app-modal>

<!-- edit user configuration modal -->
<app-modal
  [width]="'450px'"
  [height]="'auto'"
  [id]="'editDirectIntegrationModal'"
>
  <div class="relative w-full h-full">
    <div
      class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:w-full sm:max-w-lg sm:p-6"
    >
      <div class="sm:flex sm:items-start">
        <div
          class="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10"
        >
          <svg
            class="h-6 w-6 text-red-600"
            fill="none"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z"
            />
          </svg>
        </div>
        <div class="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left w-full">
          <h3
            class="text-base font-semibold leading-6 text-gray-900"
            id="modal-title"
          >
            Edit Configuration
          </h3>
          <div class="mt-4 w-full">
            <!-- Add the ngSubmit event to call editConfiguration -->
            <form
              #editDirectIntegrationForm="ngForm"
              (ngSubmit)="editDirectConfiguration()"
              class="space-y-4"
            >
              <!-- Configuration Name Input -->
              <div>
                <label
                  for="configuration_name"
                  class="block text-sm font-medium text-gray-700"
                >
                  Configuration Name
                </label>
                <div class="mt-2">
                  <input
                    id="configuration_name"
                    name="configuration_name"
                    [(ngModel)]="userIntegration.configuration_name"
                    #configuration_name="ngModel"
                    type="text"
                    required
                    class="block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    placeholder="Enter configuration name"
                  />
                  <!-- Error message for configuration name -->
                  <div
                    *ngIf="
                      configuration_name.invalid && configuration_name.touched
                    "
                    class="text-red-500 text-xs mt-0.5 ml-0.5"
                  >
                    Configuration name is required.
                  </div>
                </div>
              </div>

              <!-- Bot Token Input -->
              <div>
                <label
                  for="command_slash_token"
                  class="block text-sm font-medium text-gray-700"
                  >Slash Command Token</label
                >
                <div class="mt-2">
                  <input
                    id="command_slash_token"
                    name="command_slash_token"
                    [(ngModel)]="userIntegration.command_slash_token"
                    #slash_token="ngModel"
                    type="text"
                    required
                    class="block w-full rounded-md border-0 px-3 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    placeholder="Enter your bot token"
                  />
                  <!-- Error message for bot token -->
                  <div
                    *ngIf="slash_token.invalid && slash_token.touched"
                    class="text-red-500 text-xs mt-0.5 ml-0.5"
                  >
                    Bot token is required.
                  </div>
                </div>
              </div>

              <!-- Submit and Cancel Buttons -->
              <div class="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <button
                  type="submit"
                  [disabled]="
                    editDirectIntegrationForm.invalid ||
                    editDirectConfigurationClicked
                  "
                  [ngStyle]="{
                    opacity:
                      editDirectIntegrationForm.invalid ||
                      editDirectConfigurationClicked
                        ? '0.3'
                        : '1'
                  }"
                  class="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                >
                  Edit
                </button>
                <button
                  type="button"
                  (mousedown)="closeModal()"
                  class="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</app-modal>
