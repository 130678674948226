import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

interface ModalData<T = any> {
  // Use a generic type parameter T
  modalName: string;
  data: T; // This can be any type
}

@Injectable({
  providedIn: 'root',
})
export class ModalCommunicationService {
  constructor() {}

  private modalActionSource = new Subject<ModalData>();
  private deleteActionTextSource = new Subject<any>();
  private deleteActionQuestionSource = new Subject<any>();
  private deleteActionDocumentSource = new Subject<any>();
  private deleteActionFlaggedQuestionSource = new Subject<any>();
  private deleteActionUserIntegrationSource = new Subject<any>();
  private editActionUserIntegrationSource = new Subject<any>();
  private deleteActionDirectIntegrationSource = new Subject<any>();
  private editActionDirectIntegrationSource = new Subject<any>();

  modalAction$ = this.modalActionSource.asObservable();
  deleteTextAction$ = this.deleteActionTextSource.asObservable();
  deleteQuestionAction$ = this.deleteActionQuestionSource.asObservable();
  deleteDocumentAction$ = this.deleteActionDocumentSource.asObservable();
  deleteFlaggedQuestionAction$ =
    this.deleteActionFlaggedQuestionSource.asObservable();
  deleteUserIntegrationAction$ =
    this.deleteActionUserIntegrationSource.asObservable();
  editUserIntegrationAction$ =
    this.editActionUserIntegrationSource.asObservable();
  deleteDirectIntegrationAction$ =
    this.deleteActionDirectIntegrationSource.asObservable();
  editDirectIntegrationAction$ =
    this.editActionDirectIntegrationSource.asObservable();
  openModal<T>(modalName: string, data: T): void {
    this.modalActionSource.next({ modalName, data });
  }

  confirmTextDelete(data: any): void {
    this.deleteActionTextSource.next(data);
  }

  confirmQuestionDelete(data: any): void {
    this.deleteActionQuestionSource.next(data);
  }

  confirmDocumentDelete(data: any): void {
    this.deleteActionDocumentSource.next(data);
  }

  confirmFlaggedQuestionDelete(data: any): void {
    this.deleteActionFlaggedQuestionSource.next(data);
  }

  confirmIntegrationDelete(data: any): void {
    this.deleteActionUserIntegrationSource.next(data);
  }

  confirmIntegrationEdit(data: any): void {
    this.editActionUserIntegrationSource.next(data);
  }

  confirmDirectIntegrationDelete(data: any): void {
    this.deleteActionDirectIntegrationSource.next(data);
  }

  confirmDirectIntegrationEdit(data: any): void {
    this.editActionDirectIntegrationSource.next(data);
  }
}
